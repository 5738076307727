import React from 'react';

const Tokenomics = () => {
  return (
    <div className="max-w-7xl md:px-8 px-4 mx-auto" id="tokenomics">
      <div className="py-8">
        {/* Tokenomics title with gradient */}
        <p className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-yellow-500 to-yellow-400 text-4xl acc lg:text-6xl font-bold text-center ">
          TOKENOMICS
        </p>
        {/* Tokenomics Info */}
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4 border p-3 md:p-8 rounded-xl border-yellow-500">
            <div className='flex items-center justify-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Name: Rexas Finance</p>
            </div>
            <div className='md:border-x-4 border-x-2 border-yellow-500 flex items-center justify-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Symbol: R[10px]</p>
            </div>
            <div className='flex items-center justify-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Tokens: 1,000,000,000</p>
            </div>
          </div>
        </div>
        {/* Contract Address */}
        <div className="mt-6 border p-3 md:p-8 rounded-xl border-yellow-500 flex items-center justify-center flex-col text-center">
          <p className="text-gray-200  md:text-3xl text-[10px]">
            Contract Address: 
            <span className="font-normal ml-2">
              0x9eAeBd7E73D97E78c77fAB743e6FFA1b550e224c
            </span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              className="inline ml-2"
              style={{ color: 'white', fontSize: '15px' }}
            >
              <rect
                width="336"
                height="336"
                x="128"
                y="128"
                fill="none"
                strokeLinejoin="round"
                strokeWidth="32"
                rx="57"
                ry="57"
              ></rect>
              <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M383.5 128l.5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24"
              ></path>
            </svg>
          </p>
          <p className="text-yellow-500 md:text-lg text-[8px] mt-2">
            Please note that you should not send any tokens to this address, as doing so may result in the permanent loss of the tokens.
          </p>
        </div>
        {/* Token Distribution */}
        <div className="mt-6 border p-3 md:p-8 rounded-xl border-yellow-500 flex items-center justify-center  ">
          <div className="grid grid-cols-4 w-full">
            <div className='flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Marketing: 3%</p>
            </div>
            <div className='md:border-x-4 border-x-2 border-yellow-500 flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Presale: 42.5%</p>
            </div>
            <div className='md:border-r-4 border-r-2 border-yellow-500 flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Liquidity: 15%</p>
            </div>
            <div className='flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Treasury: 10%</p>
            </div>
          </div>
        </div>
        <div className="mt-6 border p-3 md:p-8 rounded-xl border-yellow-500 flex items-center justify-center flex-col text-center">
          <div className="grid grid-cols-4 w-full">
            <div className='flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Team: 3%</p>
            </div>
            <div className='md:border-x-4 border-x-2 border-yellow-500 flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Giveaway: 2%</p>
            </div>
            <div className='md:border-r-4 border-r-2 border-yellow-500 flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Partner: 2%</p>
            </div>
            <div className='flex items-center justify-center  text-center'>
              <p className="text-gray-200 md:text-2xl text-[10px]">Staking Pool: 22.5%</p>
            </div>
          </div>
        </div>
        {/* Tokenomics Image */}
        <div className="mt-8">
          <img src={require("../assets/token.e67069ff4c78ede84578.png")} alt="Tokenomics" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
