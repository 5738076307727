import React from 'react';
import { Link } from 'react-router-dom';


const RexasFeatures = () => {
  const features = [
    {
      imgSrc: require("../assets/fig1.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas Token Builder',
      description:
        'Tokenize your real world assets effortlessly. Rexas Token Builder platform eliminates the complexity of blockchain coding, enabling users to launch tokens in minutes.',
    },
    {
      imgSrc: require("../assets/fig2.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas Launchpad',
      description:
        'Kickstart your token funding with Rexas Launchpad. This decentralized launchpad provides a secure and transparent environment for token sales on multiple blockchain networks.',
    },
    {
      imgSrc: require("../assets/fig3.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas GenAI',
      description:
        'Harness the power of AI with Rexas GenAI for generating unique, high-quality digital artworks. Ideal for artists and creators looking to venture into the NFT space.',
    },
    {
      imgSrc: require("../assets/fig4.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas DeFi',
      description:
        'Rexas DeFi offers a powerful decentralized solution for cryptocurrency trading, enabling users to effortlessly swap crypto across multiple networks.',
    },
    {
      imgSrc: require("../assets/fig5.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas Estate',
      description:
        'Invest in real estate with Rexas, co-own real world properties and earn passive income in stable coins.',
    },
    {
      imgSrc: require("../assets/fig6.png"),
      small: require("../assets/card1.svg"),
      title: 'Rexas Treasury',
      description:
        'Multi-chain yield optimizer that allows users to earn compound interest on their crypto deposits.',
    },
  ];

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 py-20 lg:gap-8 lg:px-20 px-10">
      {features.map((feature, index) => (
        <Link to={"/connect"} key={index} className="relative backdrop-blur-sm border rounded-xl border-gray-700 text-white p-8 shadow-lg  text-center">
            {/* <img src={feature.small} alt="" /> */}
          <div className="flex justify-center mb-4">
            <img src={feature.imgSrc} alt={feature.title} className="w-3/5" />
          </div>
          <p className="text-lg lg:text-3xl font-semibold mb-2">{feature.title}</p>
          <p className="text-gray-100 lg:text-md text-xs">{feature.description}</p>
        </Link>
      ))}
    </div>
  );
};

export default RexasFeatures;
