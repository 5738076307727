import React from 'react'
import eco from "../assets/eco.png"

const Eco = () => {
  return (
    <div>
        <div className="container mx-auto px-4 xl:max-w-screen-xl">
      <div className="grid lg:grid-cols-2 gap-5">
        {/* Image Section */}
        <div>
        <p className="text-transparent lg:hidden bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-400 lg:text-5xl text-4xl mt-10 text-center lg:text-start acc">
            REXAS ECOSYSTEM</p>
          <img 
            src={eco} 
            alt="eWallet" 
            className="w-full"
          />
        </div>
        
        {/* Text Section */}
        <div>
          <p className="text-transparent hidden lg:block bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-400 lg:text-6xl text-4xl text-center lg:text-left acc">
            REXAS ECOSYSTEM
          </p> 
          <div className='lg:px-12 lg:py-10  font-semibold text-gray-200'>

          <p className="lg:text-lg text-xs text-center md:text-start">
          Explore the Rexas ecosystem, designed to streamline your journey into the world of blockchain and digital assets. Whether you're looking to invest in real estate, tokenize your assets, or engage in creative endeavors through NFTs, Rexas provides the tools you need. Benefit from a secure platform for trading and token funding, while maximizing your investments with advanced yield-optimizing strategies. Our seamless multi-chain technology ensures that your trading is efficient and your investments grow.
          </p>
          
          </div>
        </div>

        
      </div>
    </div>

    </div>
  )
}

export default Eco