import { useState } from 'react';

const FAQ = () => {
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleAccordionToggle = (panel) => {
    setExpandedPanel(expandedPanel === panel ? null : panel);
  };

  return (
    <div id="faqs" className="container text-gray-200 mx-auto max-w-6xl py-8">
      <p className="text-2xl text-center lg:text-4xl acc font-bold mb-10">FAQs</p>
      
      {/* FAQ Item 1 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel1')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">What is Rexas Finance?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel1' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel1' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
              Rexas Finance is a blockchain platform designed to facilitate the tokenization of real-world assets. Our ecosystem offers tools to tokenize, invest, and manage assets such as real estate, precious metals, and other valuable commodities, leveraging blockchain technology for transparency and efficiency.
            </p>
          </div>
        )}
      </div>

      {/* FAQ Item 2 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel2')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">What types of assets can I tokenize with Rexas Finance?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel2' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel2' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
              Rexas Finance supports the tokenization of a wide variety of assets, including but not limited to real estate, art, gold, and corporate bonds. Our platform provides the flexibility to bring almost any valuable asset onto the blockchain.
            </p>
          </div>
        )}
      </div>

      {/* Repeat similar structure for other FAQ items */}

      {/* FAQ Item 3 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">How can I start investing with Rexas Finance?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            To begin investing with Rexas Finance, Head over to rexas.com https://rexas.com Click Connect Wallet and connect using your DeFi Web3 wallet, we recommend using MetaMask & Trust Wallet, Make sure you are connected on Ethereum Chain (ERC20) and have enough ETH on ethereum network. Select from ETH, USDT, USDC, Input amount you want to purchase with and click on Buy Now. Complete the transaction in your wallet. For detailed guide on How To Buy Rexas Finance - Click Here. https://rexas.com/how-to-buy/
            </p>
          </div>
        )}
      </div>
      {/* FAQ Item 4 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">What is RXS Token?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            RXS is the symbol of Rexas Finance token. RXS token is used for governance of the Rexas Finance and Ecosystem.
            </p>
          </div>
        )}
      </div>
      {/* FAQ Item 5 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">How will I receive RXS Tokens?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            Rexas Finance (RXS) tokens are transferred to your wallet in real time, to see the amount of tokens in your wallet please add RXS as custom token in your wallet.
            </p>
          </div>
        )}
      </div>
      {/* FAQ Item 6 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">How can I contact Rexas Finance?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            You can contact us using our contact us form: https://rexas.com/contact-us/ Or buy emailing us at: support@rexas.com
            </p>
          </div>
        )}
      </div>
      {/* FAQ Item 7 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">Where can I sell my RXS Tokens?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            Since we are currently on presale, you cannot sell or transfer your tokens. Once Rexas Finance goes live, users will be able to freely trade it on Uniswap.
            </p>
          </div>
        )}
      </div>
      {/* FAQ Item 8 */}
      <div className="border-b p-8 rounded-md mb-4 border-gray-300 bg-transparent w-full">
        <div 
          onClick={() => handleAccordionToggle('panel3')} 
          className="flex justify-between items-center py-4 cursor-pointer"
        >
          <div className="text-lg lg:text-4xl font-semibold">My wallet is Hacked, What should I do?</div>
          <svg
            className="w-7 h-7"
            style={{ color: expandedPanel === 'panel3' ? 'gray-200' : 'gray-200' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </div>
        {expandedPanel === 'panel3' && (
          <div className="py-4">
            <p className="text-gray-500 text-xs md:text-xl">
            Unfortunately, there is nothing we can do on our end in this situation. Never Share Your Seed Phrase or Private Key: Under no circumstances should you share your Seed Phrase or Private Key with anyone. These are sensitive and should be kept secure.
            </p>
          </div>
        )}
      </div>

      {/* More FAQ items can be added similarly */}
    </div>
  );
};

export default FAQ;
