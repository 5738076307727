import React from "react";
import { IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>

      <div className="relative w-full overflow-hidden bg-[#8d571f] py-3 text-white">
        <div className="flex animate-marquee">
          <div className="flex-none min-w-full">
            <p className="font-medium  text-base">
              <span className="font-bold">Please Note:</span> REXAS Team will never send you messages on any social networks. Please verify our social links via our website: Rexas.com | Never Share Your Seed Phrase or Private Key with anyone.
            </p>
          </div>
          <div className="flex-none min-w-full">
            <p className="font-semibold text-base">
              <span className="font-bold">Please Note:</span> REXAS Team will never send you messages on any social networks. Please verify our social links via our website: Rexas.com | Never Share Your Seed Phrase or Private Key with anyone.
            </p>
          </div>
        </div>
      </div>

      <div className="navbar  lg:px-6 w-full  text-white">
        <div className="navbar-start">

          <a className="btn btn-ghost text-lg lg:text-xl acc">REXAS FINANCE</a>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1 font-bold text-lg">
            <li><a>Ecosystem</a></li>
            <li><a>Tokenomics</a></li>
            <li><a>Whitepaper</a></li>
            <li><a>Win $1M</a></li>
            <li><a>How To Buy</a></li>
            <li><a>Contact-us</a></li>
            
          </ul>
        </div>
        <div className="navbar-end hidden lg:flex">
          <Link to={"/connect"}

          >
            <div className="cardBox">
              <div className="card-animation">
                <div className="card-content flex justify-center items-center">
                  <h2 className="text-white text-sm font-bold flex items-center">

                    Connect Wallet
                  </h2>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="navbar-end lg:hidden">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <IoMenu size={32} />
            </div>

          </div>
        </div>
      </div>


      <div className="navbar backdrop-blur-sm top-0 hidden text-white py-4 z-10">
        <div className="navbar-start">

          <Link to={"/"} className=" px-4 font-extrabold text-xl flex items-center justify-center gap-2"><img src={require("../asset/synclogo2.png")} className="w-10" alt="" /> SyncAI</Link>
        </div>

        <div className="navbar-end">
          <Link to={"/connect"} className="btn grad text-white border-0">Sync Wallet</Link>
        </div>
      </div>


    </div>
  );
};

export default Navbar;
