import React from 'react'
import ewallet1 from "../assets/ewallet1.png"

const Unlock = () => {
  return (
    <div>
        <div className="container mx-auto px-4 xl:max-w-screen-xl">
      <div className="flex flex-col-reverse lg:flex-row gap-5">
        {/* Text Section */}
        <div>
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-400 lg:text-5xl text-xl text-center lg:text-start acc">
            Unlock the World of Asset Investment with Ease and Flexibility
          </p> 
          <div className='lg:px-20 lg:py-10 p-4 font-semibold text-gray-200'>

          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Diverse Asset Range:</span> Seamlessly invest in a wide range of Real World Assets.
          </p>
          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Fractional Ownership:</span> Participate in fractional ownership, making previously inaccessible investment opportunities available to all.
          </p>
          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Democratize Investment:</span> Our platform democratizes access, offering a simple, secure, and transparent way to diversify your portfolio.
          </p>
          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Accessible Real Estate Investment:</span> Invest in real estate without the high entry costs.
          </p>
          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Diverse Opportunities:</span> Own a piece of precious metals or thriving businesses with just a few clicks.
          </p>
          <p className="lg:text-lg text-xs">
            <span className="text-yellow-500">Global Market Access:</span> Rexas brings the potential of the global market directly to your fingertips.
          </p>
          </div>
        </div>

        {/* Image Section */}
        <div>
          <img 
            src={ewallet1} 
            alt="eWallet" 
            className="w-full"
          />
        </div>
      </div>
    </div>

    </div>
  )
}

export default Unlock