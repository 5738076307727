import React from 'react'
import download from "../assets/download.png"
import download2 from "../assets/download2.png"
import download3 from "../assets/download3.png"
import download4 from "../assets/download4.png"
import download5 from "../assets/download5.png"
import download6 from "../assets/download6.png"
import download7 from "../assets/download7.png"
import download8 from "../assets/download8.png"
import download9 from "../assets/download9.png"
import download10 from "../assets/download10.png"
import download11 from "../assets/download11.png"
import download12 from "../assets/download12.png"
import download13 from "../assets/download13.png"
import download14 from "../assets/download14.png"
import download15 from "../assets/download15.png"
import download16 from "../assets/download16.png"

const Featured = () => {
    const list_of_images = [
        download, download2, download3, download4,download5,download6,download7,download8,download9,download10,download11,download12,download13,download14,download15,download16,
    ]
  return (
    <div className='text-gray-200 w-full text-center '>
        <h1 className='text-3xl font-semibold'>Featured In</h1>

        <div className='flex gap-20 animate-marquee w-full  my-10'>
      {list_of_images.map((image, i) => (
        <img
          key={i}
          src={image}
          className='h-10 object-cover'  // Set a fixed width (e.g., w-40)
          alt={`Image ${i}`}
        />
      ))}
    </div>
    </div>
  )
}

export default Featured