import React, { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import Speciality from '../components/Speciality';
import myHtml from '../components/Html';
import Conv from '../components/Conv';
import Shiba from '../components/Shiba';
import Sib from '../components/Sib';
import Rexas from '../components/Rexas';
import Featured from '../components/Featured';
import Unlock from '../components/Unlock';
import Eco from '../components/Eco';
import RexasFeatures from '../components/RexasFeatures';
import Tokenomics from '../components/Tokenomics';
import FAQ from '../components/Faq';
import Footer from '../components/Foot';
import ScrollToTop from '../components/ScrollToTop';


const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div className='  mx-auto   overflow-x-hidden flex flex-col justify-center items-center'>
      
        <>
        <ScrollToTop />
        <Rexas />
        <Featured />
        <Unlock />
        <Eco />
        <RexasFeatures />
        <Tokenomics />
        <FAQ />
        <Footer />
        {/* <Fredomf /> */}
        {/* <Sib /> */}
        {/* <Shiba /> */}
        {/* <Conv /> */}
        {/* <myHtml /> */}
          {/* <Banner /> */}
          {/* <Speciality /> */}
        </>
     
    </div>
  );
};

export default Home;
