import React from 'react'
import eth from "../assets/eth20.svg"
import usdt from "../assets/usdt20.svg"
import eth1 from "../assets/eth1.png"
import logorx from "../assets/logorx.svg"
import { Link } from 'react-router-dom'

const Rexas = () => {
    return (
        <div className='text-gray-200 py-20 flex flex-col items-center justify-center text-center gap-4 px-10 lg:px-44 poppins'>
            <h1 className=' text-3xl lg:text-5xl font-bold'><span className="bg-clip-text acc text-transparent bg-gradient-to-l from-yellow-400 via-pink-400 to-pink-500">
                Rexas Finance
            </span>
            </h1>

            <p className='text-2xl lg:text-5xl  font-semibold'>Tokenize Real World Assets Anything, Anywhere</p>

            <p className='text-md lg:text-lg'>
                Rexas Finance is your gateway to the future of asset management. Rexas Finance empowers you to own or tokenize virtually any real-world asset, from real estate and art to commodities and intellectual property, on a global scale. With Rexas Finance, you gain access to a world where asset liquidity and investment opportunities are boundless. Embrace the power of blockchain technology to make investing more inclusive, transparent, and efficient.
            </p>

            <div className='py-4 flex items-center'>
                <a
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    className="no-underline mr-5"
                >
                    <div className="cardBox">
                        <div className="card-animation">
                            <div className="card-content flex justify-center items-center">
                                <h2 className="text-yellow-400 text-lg font-medium flex items-center">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 512 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-yellow-400 text-2xl mr-1"
                                    >
                                        <path
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="32"
                                            d="M461.81 53.81a4.4 4.4 0 0 0-3.3-3.39c-54.38-13.3-180 34.09-248.13 102.17a294.9 294.9 0 0 0-33.09 39.08c-21-1.9-42-.3-59.88 7.5-50.49 22.2-65.18 80.18-69.28 105.07a9 9 0 0 0 9.8 10.4l81.07-8.9a180.29 180.29 0 0 0 1.1 18.3 18.15 18.15 0 0 0 5.3 11.09l31.39 31.39a18.15 18.15 0 0 0 11.1 5.3 179.91 179.91 0 0 0 18.19 1.1l-8.89 81a9 9 0 0 0 10.39 9.79c24.9-4 83-18.69 105.07-69.17 7.8-17.9 9.4-38.79 7.6-59.69a293.91 293.91 0 0 0 39.19-33.09c68.38-68 115.47-190.86 102.37-247.95zM298.66 213.67a42.7 42.7 0 1 1 60.38 0 42.65 42.65 0 0 1-60.38 0z"
                                        ></path>
                                        <path
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="32"
                                            d="M109.64 352a45.06 45.06 0 0 0-26.35 12.84C65.67 382.52 64 448 64 448s65.52-1.67 83.15-19.31A44.73 44.73 0 0 0 160 402.32"
                                        ></path>
                                    </svg>
                                    Whitepaper
                                </h2>
                            </div>
                        </div>
                    </div>
                </a>
                <a
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    className="no-underline mr-5"
                >
                    <div className="cardBox">
                        <div className="card-animation">
                            <div className="card-content flex justify-center items-center">
                                <h2 className="text-gray-200 text-lg font-medium flex items-center">

                                    Audit
                                </h2>
                            </div>
                        </div>
                    </div>
                </a>

            </div>

            <a href="#" className='text-white underline text-sm'>Audited | 100% Secure & Verified</a>


            <div className="grid grid-cols-12 gap-4 css-qo4kbb lg:w-7/12 w-full ">
                <div className="col-span-12 css-1yab1yo  ">
                    <div className="p-4 rounded-lg css-1bkblt6 lg:px-10   font-semibold ">
                        <div className='px-4'>
                            <div className="">
                                <p className="text-center text-3xl lg:text-4xl pb-4 font-bold  bg-clip-text text-transparent   bg-gradient-to-r from-yellow-500 to-pink-500">
                                    Rexas Presale <br /> Stage <span className="text-yellow-500  font-bold">4</span>
                                </p>
                                <p className="text-center">1 RXS = $0.060</p>
                                <p className="text-center">Next Stage Price = $0.070</p>

                                {/* Progress bar */}
                                <div className="relative w-5/5 mt-4 bg-gray-200 rounded-full">
                                    <div className="w-11/12 shimmer  h-8 bg-gradient-to-r from-yellow-500 to-pink-500 flex items-center justify-center  rounded-full">
                                        <div className="text-black text-sm text-center">91.33%</div>
                                    </div>
                                </div>

                                <p className="text-center mt-4">
                                    USD Raised: <span className="text-yellow-300">$4,217,772 / $5,450,000</span>
                                </p>
                                <p className="text-center">
                                    Tokens Sold: <span className="text-yellow-300">89,462,855 / 110,000,000</span>
                                </p>
                            </div>

                            {/* Payment methods */}
                            <div className="items-center justify-center space-x-4 mt-6 grid grid-cols-3">
                                <div className='flex items-center justify-center border-2 rounded-lg px-4 py-2'>
                                    <img src={eth} width="68" alt="ETH" />
                                </div>
                                <div className='flex items-center justify-center border-2 rounded-lg px-4 py-2'>

                                    <img src={usdt} className='' width="68" alt="USDT" />
                                </div>
                                <a
                                    href="#"
                                    rel="noreferrer"
                                    className="flex items-center justify-center text-gray-200 bg-gradient-to-r from-yellow-500 to-pink-500 rounded-lg px-4 py-2"
                                >
                                    <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAAArCAYAAABl25buAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOLSURBVHgB7ZmNdeIwDIDFvRuADZpOUDa43ARlg+YmaDsB6QSlE9CboNwEYQPoBOQmgE7gky4yEcZxfuiDF6rvPb0ktiU7/pUSAEVRFEVRFEVRFEVRlIG9McaM8DKEEzIYDBaghMGBmZjzMAGlkv8rBztpA8WqyeF0RPaKK+gvKH7ETI7hBGA9kajzChQv3+FCoAGH8szMcTVuoed8g55jivNyjbckS5YNpmUoSUMbb2IlPwTKpYHz01tfQGfXRp5YB7gr5xkLnmLGHe0V8gu9oYw4actCUF5MguVucBU91tgZi6RblCnUk4v7SNR3hfU9tdGhdNR7RL05eBp4TlqfOaY4s9asTzOQZt/QyX8VdTwHbCXCjmVYUdaugrUnbyr044Y6sXiPPT1Z6Jx0GZwZ61KHRoFycruKK8pknD8z5QClFWVDHT0U+g9NdDxtWBsxMXp35nDjE358wa0gDxT/hXLNsvLYiqDYVojfLMQPaAk7IHZbbbtt2203Eu3ppUMwEvfzUEHqMBo8Ft9Zas+anL9WWHuxaRlW8EBH/LhqoUrtXAmdW5veR1d6Nzj8Usdwz9cF21tgJ+dQdPLYpnugLSwRz7Q1260s9x7s9bxD8W6RTbiYOKctpviWGPHji8iirY0+K91B2eEutG3NPOk5yk/4JPq4re22J1PhVTXErprcWYGvfB0GtjZqA7nLclDJFb6uOQNDWMfowyb0ceXQlmFnbQLNYhIfMV+35Kk5edT5NPBVWxudZSndoO4N27rH+9cuXyYcx2TuZp6TLq50xrp1rjQF1WuWsUiPG7aN7Mv46cAtdmylnjY0caUnwkbkZnZhSS/MjSORMcVUpEvJPHa6BqE2plgbzwA5L5w5eTOhm1SIZVzX0aYMeA8mi6mPjZ5FXanvZbuQODbkDKqKsMceO52+SqPeyOxH1zRZ3lg2Tnrk6G587+CUybhMJtKqBkcGoO5EsDqUPxOydNqZum045syZOC99K+6p8nePzh18EnSIYx3kGaVsdwT7MRDt/XRgT+U5wANiJ88iUMUfKL+XDUNnCeVhGaprwuVjz19eGTzLNlK5J99fYfuzzcD5OPpnG69U6RqvqmIgU/6O34biJGHT2tuaMtA80HXK59ZrM/vBqSRnO9WDzgZ6PTiXio1zjo20u0Kz5gMUL3Zw6APhqQcohyJwax0XKIqiKIqiKIqiKIqifB3+AUEPkjFlPcC4AAAAAElFTkSuQmCC"
                                        width="68"
                                        alt="Card"
                                    />
                                </a>
                            </div>

                            {/* Payment Form */}
                            <div className="mt-6  text-start">
                                <div className="font-medium">
                                    Amount in <span className="font-bold">ETH</span> You Pay:
                                </div>
                                <div className="flex items-center mt-2 relative">
                                    <input
                                        type="number"
                                        placeholder="Enter Amount"
                                        className="w-full h-10 text-black font-medium text-left bg-gray-200 rounded-lg px-4 border-none focus:outline-none "
                                    />
                                    <button className="ml-2 bg-gradient-to-r from-yellow-500 to-pink-500 text-black px-2 py-1 rounded-lg absolute right-10 text-sm">
                                        Max
                                    </button>
                                    <img
                                        src={eth1}
                                        alt="ETH"
                                        className="ml-2 absolute right-2"
                                        style={{ width: '27px' }}
                                    />
                                </div>
                                <div className="font-medium mt-4">
                                    Amount in <span className="font-bold">RXS</span> You Receive:
                                </div>
                                <div className="flex items-center mt-2 relative">
                                    <input
                                        type="number"
                                        placeholder="0"
                                        readOnly
                                        className="w-full h-10 text-black font-medium text-left bg-gray-200 rounded-lg px-4 border-none focus:outline-none"
                                    />
                                    <img
                                        src={logorx}
                                        alt="Syy"
                                        className="ml-2 absolute right-2 w-8"
                                    />
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="mt-6">
                                <div className='grid grid-cols-2 gap-4'>
                                <button className="w-full bg-gradient-to-l border-yellow-100 border-4  from-yellow-500 to-pink-500  text-black px-4 py-2 rounded-lg">
                                    <Link to={"/connect"} className='text-black'>
                                    Claim
                                    </Link>
                                </button>
                                <button className="w-full bg-gradient-to-l border-yellow-100 border-4  from-yellow-500 to-pink-500  text-black px-4 py-2 rounded-lg">
                                    <Link to={"/connect"} className='text-black'>
                                    Validate
                                    </Link>
                                </button>

                                </div>
                                <button className="w-full bg-gradient-to-l border-yellow-100 border-4  from-yellow-500 to-pink-500  text-black px-4 py-2 rounded-lg mt-6">
                                    <Link to={"/connect"} className='text-black'>
                                    Connect Wallet
                                    </Link>
                                </button>
                            </div>

                            <div className="hidden justify-center space-x-4 mt-4">
                                <a
                                    href="https://rexas.com/how-to-buy/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-500"
                                >
                                    How To Buy?
                                </a>
                                <a
                                    href="https://rexas.com/rexas-millionaire/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-500"
                                >
                                    $1 Million Giveaway
                                </a>
                            </div>

                            <div className="hidden justify-center mt-4">
                                <a
                                    href="https://rexas.com/help/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-500"
                                >
                                    Getting Error? Click here
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Rexas