import { FaTwitter, FaTelegramPlane, FaMedium } from 'react-icons/fa';
import { FiLink } from 'react-icons/fi';
import tw1 from "../assets/tw1.svg"
import tg1 from "../assets/tg1.svg"
import tg2 from "../assets/tg2.svg"
import medium from "../assets/medium.svg"

const Footer = () => {
  return (
    <div className="container py-20 mx-auto max-w-7xl ">
      <hr className="mb-14 border-gray-400" />
      <div className="grid grid-cols-1 gap-3">
        <div className="flex justify-center space-x-6">
          <a href="#" target="_blank" rel="noreferrer" className="text-yellow-500 text-xl">
            <img src={tw1} alt="" />
          </a>
          <a href="#" target="_blank" rel="noreferrer" className="text-yellow-500 text-xl">
            <img src={tg1} alt="" />
            </a>
          <a href="#" target="_blank" rel="noreferrer" className="text-yellow-500 text-xl">
            <img src={tg2} alt="" />
            </a>
          <a href="#" target="_blank" rel="noreferrer" className="text-yellow-500 text-xl">
            <img src={medium} alt="" />
            
          </a>
        </div>
        <div className="flex justify-center space-x-4">
          <p className="text-sm font-semibold text-gray-200">

            Win $1M
          </p>
          <p className="text-sm font-semibold text-gray-200">

            How To Buy
          </p>
          <p className="text-sm font-semibold text-gray-200">

            Contact Us
          </p>
          
        </div>
        <div className="flex justify-center space-x-4">
          <p className="text-sm font-semibold text-gray-200">

            Privacy Policy
          </p>
          <p className="text-sm font-semibold text-gray-200">
            
            Terms & Conditions
          </p>
          <p className="text-sm font-semibold text-gray-200">
            
            Cookies
          </p>
          
        </div>
      </div>
      <p className="text-center text-sm mt-4 text-gray-500">
        Disclaimer: Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate.
        Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
      </p>
      <p className="text-center text-sm mt-8 font-semibold text-gray-200">2024© Rexas Finance | All Rights Reserved</p>
    </div>
  );
};

export default Footer;
